var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index" },
    [
      _c(
        "Row",
        { attrs: { type: "flex", justify: "center", align: "middle" } },
        [_c("h1", [_vm._v("欢迎使用企业服务后台系统！")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }