<!--登录后首页-->
<style scoped lang="less">

    .index{height:100%;
        h1{line-height:100%;padding:200px 0;}
    }
    .index .ivu-row-flex {
        height: 100%;
    }
</style>
<template>
    <div class="index">
        <Row type="flex" justify="center" align="middle">
            <h1>欢迎使用企业服务后台系统！</h1>
        </Row>
    </div>
</template>
<script>
    import Cookies from 'js-cookie';
    export default {
    	data () {
            return {

            }
        },
        created() {
            if(!Cookies.get('token')) {
                window.location.href = '/';
            }
        },
        methods: {

        }
    };
</script>
